import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { TabsModule } from 'ngx-tabset';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {TextMaskModule} from "angular2-text-mask";
import {
  getAnalytics,
  provideAnalytics
} from '@angular/fire/analytics';

import { CountUpModule } from 'ngx-countup';
import { StickyNavModule } from 'ng2-sticky-nav';
import { LightboxModule } from 'ngx-lightbox';
import { AccordionModule } from "ngx-accordion";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxPaginationModule } from 'ngx-pagination';
import { DocumentEditorContainerAllModule } from '@syncfusion/ej2-angular-documenteditor';
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense("MTQ1MTk1N0AzMjMxMmUzMTJlMzMzNUNBSVVHbkxjUG1xZEZBZjVCRHpTMmlmb2lkVGYzSHVGdmJaNnlqZW90VzQ9;Mgo DSMBaFt QHFqVkNrX05Hd0BAXWFKblp8RmdTfFlgBShNYlxTR3ZcQFljQH1XdUJgUH1Z;Mgo DSMBMAY9C3t2VFhhQlJBfVxdXmJWfFN0RnNQdV10flZBcC0sT3RfQF5jSnxXdkxgXXxZdHVTQQ==;Mgo DSMBPh8sVXJ1S0d X1RPdUBBXHxLflF1VWpTfFp6d1JWESFaRnZdQV1gS3pTf0ZnWnhac3RS;MTQ1MTk2MUAzMjMxMmUzMTJlMzMzNWZvRENEbFZWUTZBMmlRL2JtcWt6V0pNMC9BcG8wVzluKzRHUTBkQVMwaWs9;NRAiBiAaIQQuGjN/V0d XU9Hc1RBQmBIYVF2R2BJdlRzcF9FZ0wgOX1dQl9gSX1RckVqWntdd3BSR2c=;ORg4AjUWIQA/Gnt2VFhhQlJBfVxdXmJWfFN0RnNQdV10flZBcC0sT3RfQF5jSnxXdkxgXXxZdHxTQQ==;MTQ1MTk2NEAzMjMxMmUzMTJlMzMzNWl5aERLcUJDSjRQelhSSE1WM3Z5VjlydDl2cmVhQWtDdnQ1N08yRm02YWM9;MTQ1MTk2NUAzMjMxMmUzMTJlMzMzNWVTUnZzLzNzV2M0MXNUcHp1SFVxY0x1VUNTL2xvd0dCdzQvNnRRWE9aZXM9;MTQ1MTk2NkAzMjMxMmUzMTJlMzMzNUVZbmJlM2gzZytpcmRJZjdSTGx6UGQ0cHJDZ0lsSGRqckRlUUppZjRuME09;MTQ1MTk2N0AzMjMxMmUzMTJlMzMzNUpkN2hvenlRTDUxUWppY2NzUHZDaFRxNGI3N2gyTkI4N3FjazhMbTRrSUk9;MTQ1MTk2OEAzMjMxMmUzMTJlMzMzNUNBSVVHbkxjUG1xZEZBZjVCRHpTMmlmb2lkVGYzSHVGdmJaNnlqZW90VzQ9");

@NgModule({
  declarations: [
    AppComponent,
    ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    //ConfirmPopupModule,
    TextMaskModule,
    FontAwesomeModule,
   // NgcCookieConsentModule.forRoot(cookieConfig),
    CommonModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    DocumentEditorContainerAllModule,
    HttpClientModule,
 
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    provideFirestore(() => {
      const firestore = getFirestore();
      
      return firestore;
    }),
    CarouselModule,
    BrowserAnimationsModule,
    CountUpModule,
    StickyNavModule,
    TabsModule,
    NgxScrollTopModule,
    LightboxModule,
    NgxExtendedPdfViewerModule,
    AccordionModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
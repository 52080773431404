import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [

   
    {
        path: '',
        redirectTo: 'zoom',
        pathMatch: 'full'
    },
    {path: 'zoom', loadChildren:() => import('./pages/zoom/zoom.module').then(mod => mod.ZoomCustomModule)},
   
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
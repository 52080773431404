import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { getAuth, signOut } from 'firebase/auth';
import { FirebaseApp } from '@angular/fire/app';


 declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    public isAdmin = false;
    public isAuthenticated:any = false;
    serviceLink = 'https://ej2services.syncfusion.com/production/web-services/api/documenteditor';
    routerSubscription: any;
//keep refs to subscriptions to be able to unsubscribe later

constructor(private afApp:FirebaseApp, private router: Router) {


       
    }

    ngOnInit(){
            
    } 
}
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  firebase: {
   apiKey: "AIzaSyCbeDWrJkzNuTSJjJpLkrqVo2etetAYC9s",
  authDomain: "centre-de-formation-biblique.firebaseapp.com",
  projectId: "centre-de-formation-biblique",
  storageBucket: "centre-de-formation-biblique.appspot.com",
  messagingSenderId: "130877945855",
  appId: "1:130877945855:web:0fd1a2bb628901c4c2d9ca",
  measurementId: "G-WX37WQKR5N"
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
